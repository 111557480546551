import React from "react";

import './styles.css';

import TextInput from '../../TextInput/TextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import LinearLoader from "../../../assets/loaders/LinearLoader";

function ForgotPassword({
    email, setEmail, emailWarning, onClick, nav, loading
}) {

    return(
        <form className="forgot-password-container">
                <TitleOn title='Esqueci minha senha' />
                <div className="forgot-password-card">
                    <div className="forgot-password-form-container">
                        <p className="forgot-password-form-title">Vamos te ajudar!</p>
                        <p className="forgot-password-form-subtitle">Lembrou sua senha? <span className="create-account" onClick={nav}>Volte e logue aqui</span></p>
                            <div className="forgot-password-form-inputs-container">
                                <TextInput
                                    label="Seu email cadastrado"
                                    value={email}
                                    setValue={setEmail}
                                    warningMessage={emailWarning}
                                />
                            </div>
                        <button type="submit" className="forgot-password-form-button" onClick={onClick}>
                        {
                            loading ? <LinearLoader white /> : <p>Enviar email</p>
                        }
                        </button>                      
                    </div>
            </div>
        </form>
    )
}   

export default ForgotPassword;