import React, { useState, useEffect } from 'react';

import useAuthAdmin from '../../../hooks/admin/useAuthAdmin';
import useFetchAllDraws from '../../../hooks/admin/useFetchAllDraws';
import useSetPageTitle from '../../../hooks/useSetPageTitle';

import SideBarAdmin from '../../../layout/admin/SideBar/SideBar';

import EmailTable from '../../../components/Admin/EmailTable/EmailTable';
import PopupAddress from '../../../components/Admin/PopupAddress/PopupAddress';

import LinearLoader from "../../../assets/loaders/LinearLoader";

import SearchBar from '../../../components/TextInput/SearchBar';

import './styles.css';

function EmailChangeRequests() {

    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');

    const { drawsOption } = useFetchAllDraws(1);
    const [draw, setDraw] = useState('');

    const [popupAddress, setPopupAddress] = useState(false);
    const [address, setAddress] = useState({});

    const [loadingExport, setLoadingExport] = useState(false);
    const [errorExport, setErrorExport] = useState('');

    const [search, setSearch] = useState('');
    const [query, setQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const handleSearch = (search) => {
        setSearch(search);
    }

    const executeSearch = () => {
        setQuery(search);
        setCurrentPage(1); // Reset to first page on new search
    }

    useEffect(() => {
        if (search.trim() === '') {
            setQuery('');
            setCurrentPage(1);
        }
    }, [search]);

    return (
        <div className="admin-home-background">
            <SideBarAdmin />
            { popupAddress ? <PopupAddress setPopup={setPopupAddress} address={address} /> : false }
            <div className="admin-page-container">
                <div className="admin-page-title-container">
                    <p className="admin-page-title">Trocas de e-mail</p>
                    <p className="admin-page-error-import">{errorExport}</p>
                </div>
                <div className="admin-page-select-input-container">
                    <SearchBar setValue={handleSearch} rubik />
                    <button onClick={executeSearch} className="search-button">Buscar</button>
                    { loadingExport ? <LinearLoader /> : false }
                </div>
                <div style={{ marginBottom: '32px' }} />
                <EmailTable 
                    search={query} 
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                    setPopup={setPopupAddress} 
                    setAddress={setAddress} 
                />
            </div>
        </div>
    )
}

export default EmailChangeRequests;
