import React, { useRef, useEffect, useState } from "react";

import TopBar from "../../layout/TopBar/TopBar";
import Banner from "../../components/Home/Banner/Banner";
import BuyNow from "../../components/Home/BuyNow/BuyNow";
import CardsContainer from "../../components/Home/CardsContainer/CardsContainer";
import Prizes from "../../components/Home/Prizes/Prizes";
import Aceleradores from "../../components/Home/Aceleradores/Aceleradores";
import Products from "../../components/Home/Products/Products";
import Footer from "../../components/Home/Footer/Footer";
import Popup from "../../components/Home/Popup/Popup";
import PopupSuccess from "../../components/Home/PopupSuccess/PopupSuccess";

import useAuthClient from '../../hooks/useAuthClient';
import useHaveVouchers from "../../hooks/useHaveVouchers";
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

import logo from '../../assets/images/toby-carona-logoHome-white.png'
import hello from '../../assets/images/toby-hello.png';

function Home() {

    const [popup, setPopup] = useState(localStorage.getItem('popupClosed') === 'false');
    const [popupSuccess, setPopupSuccess] = useState(localStorage.getItem('firstTime') === 'true');

    const prizesRef = useRef();
    const aceleradoresRef = useRef();
    const productsRef = useRef();

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Home');
    const haveVouchers = useHaveVouchers();
    const { pendingPrizes } = useFetchClientsResults();
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'auto' });

    useEffect(() => {
        if (popup || popupSuccess) {
            scrollToTop();
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [popup, popupSuccess]);

    return(
        <div className="background">
            <TopBar />
            <div className="home-background">
                <div className="home-greet-container">
                    <p className="home-greet">Olá, {localStorage.getItem('name')}</p>
                    <img className="greet-toby" src={hello} alt="hello" />
                </div>
                { pendingPrizes ? <Popup setPopup={setPopup} /> : false }
                { popupSuccess ? <PopupSuccess setPopup={setPopupSuccess} /> : false }
                <Banner haveVouchers={haveVouchers} pendingPrizes={pendingPrizes} />
                <BuyNow />
                <div className="home-logo-container">
                    <img className="home-logo" alt="logo" src={logo} />
                </div>
                <CardsContainer prizesRef={prizesRef} aceleradoresRef={aceleradoresRef} productsRef={productsRef} /> 
                <Prizes prizesRef={prizesRef} />
                <Aceleradores aceleradoresRef={aceleradoresRef} /> 
                <Products productsRef={productsRef} />
                <Footer />
            </div>
        </div>
    )
}

export default Home;