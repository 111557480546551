import tobyOnLogo from '../../../assets/images/toby-ta-ligado-kv.png';
import tobyShowLogo from '../../../assets/images/toby-show-de-premios.png';
import tobyCamisa10 from '../../../assets/images/toby-camisa-10.png';
import tobyCarona from '../../../assets/images/toby-carona.png';

const getPromoAtivaStatus = () => localStorage.getItem('PromoAtiva') === 'true';
const getElegivelPromoStatus = () => localStorage.getItem('ElegivelPromo');

const promos = [
  {
    title: 'Toby Carona Premiada',
    description: getPromoAtivaStatus()
      ? 'Parabéns! Faça uma compra de no mínimo R$50 em produtos participantes e comece a acumular números da sorte agora mesmo!'
      : 'A promoção começou! Clique no botão abaixo para participar!',
    image: tobyCarona,
    ended: !getElegivelPromoStatus(),
    joined: getPromoAtivaStatus(),
    new: true,
  },
  {
    title: 'Toby Tá Ligado',
    description: 'Promoção Finalizada',
    image: tobyOnLogo,
    ended: true,
    joined: false,
    new: false,
  },
  {
    title: 'Toby Show de Prêmios',
    description: 'Promoção Finalizada',
    image: tobyShowLogo,
    ended: true,
    joined: false,
    new: false,
  },
  {
    title: 'Toby Camisa 10',
    description: 'Promoção Finalizada',
    image: tobyCamisa10,
    ended: true,
    joined: false,
    new: false,
  },
];

export default promos;
