import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import HomeCard from '../HomeCard/HomeCard';

import prizes from '../../../assets/cards/prize.png';
import products from '../../../assets/cards/products.png';
import mecanica from '../../../assets/cards/mecanica.png';
import luck from '../../../assets/cards/luck.png';
import results from '../../../assets/cards/results.png';
import myPrizes from '../../../assets/cards/my-prizes.png';

function CardsContainer({ productsRef, prizesRef, aceleradoresRef }) {
    const navigate = useNavigate();

    const scrollToPrizes = () => window.scrollTo({ behavior: 'smooth', top: prizesRef.current.offsetTop - 30 });
    const scrollTomecanica = () => window.scrollTo({ behavior: 'smooth', top: aceleradoresRef.current.offsetTop - 30 });
    const scrollToProducts = () => window.scrollTo({ behavior: 'smooth', top: productsRef.current.offsetTop - 30 });

    return(
        <div className="home-cards-container">
            <HomeCard text="Prêmios" icon={prizes} onClick={scrollToPrizes} />
            <HomeCard text="Como funciona" icon={mecanica} onClick={scrollTomecanica} />
            <HomeCard text="Produtos participantes" icon={products} onClick={scrollToProducts} />
            <HomeCard text="Resultados" icon={results} onClick={() => navigate('/resultados')} />
            <HomeCard text="Meus números" icon={luck} onClick={() => navigate('/numeros')} />
            <HomeCard text="Meus prêmios" icon={myPrizes} onClick={() => navigate('/premios')} />
        </div>
    )
}

export default CardsContainer;