import { useEffect } from 'react';
import axios from 'axios';

const useFetchEachDraw = (promoId, setDrawsWeek, setDrawsMonth, setDrawsFinal, setLoading, setError) => {

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/sorteios?promo_id=2`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
        },
      })
      .then((response) => {
        setLoading(false);
        setError(false);
        setDrawsWeek(response.data.semanal);
        setDrawsMonth(response.data.mensal);
        setDrawsFinal(response.data.final);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [promoId, setError, setLoading, setDrawsFinal, setDrawsMonth, setDrawsWeek]);

};

export default useFetchEachDraw;
