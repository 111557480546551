import React from "react";

import './styles.css';

function HomeCard({ text, icon, onClick }) {
    return(
        <div className="home-card-container" onClick={onClick}>
            <img src={icon} alt="card-icon" className="home-card-icon" />
            <p className="home-card-text">{text}</p>
        </div>
    )
}

export default HomeCard;