import React from "react";

import SideBarAdmin from "../../../layout/admin/SideBar/SideBar";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import './styles.css';

function HomeAdmin() {
    useAuthAdmin();
    useSetPageTitle('Promos Juntos+ | Admin');
    return(
        <div className="admin-home-background">
            <SideBarAdmin />
            <div className="admin-page-container">
                <p className="admin-page-title">Toby Carona Premiada</p>
            </div>
        </div>
    )
}

export default HomeAdmin;