import axios from 'axios';

const tryImportingVouchers = (file, setLoading, setErrorImport, setSuccessImport) => {
    if (!file) {
        setErrorImport('Arquivo obrigatório.');
        setSuccessImport('');
        return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('promo_id', 2);
    axios
        .post(`https://${window.location.host !== 'toby.promo' ? 'dev-api' : 'api'}.toby.promo/api/v1/admin/vouchers`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            setLoading(false);
            setSuccessImport('Sua base foi importada com sucesso.');
            setErrorImport('');
        })
        .catch((err) => {
            setSuccessImport('');
            setErrorImport(err.response.data.message);
            setLoading(false);
        })
}

export default tryImportingVouchers;