import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from '../../TextInput/TextInput';
import HideTextInput from '../../TextInput/HideTextInput';
import TitleOn from "../../../layout/TitleOn/TitleOn";
import LinearLoader from "../../../assets/loaders/LinearLoader";
import promo from '../../../assets/images/toby-carona.png';
import './styles.css';

function LoginForm({
    doc, setDoc, docWarning,
    password, setPassword, passwordWarning,
    onClick, dontHaveAnAccount, forgotPassword,
    loading
}) {
    const [errorMessage] = useState('');
    const navigate = useNavigate();

    const handleForgotPasswordClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'esqueci-senha',
        });
        navigate('/esqueci-minha-senha');
    };

    const handleForgotEmailClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'esqueci-email',
        });
        navigate('/esqueci-meu-email');
    };

    return (
        <form className="login-container">
            <TitleOn title='Login' />
            <div className="login-card">
                <div className="login-left">
                    <img src={promo} className="promo-kv" alt="promo-kv" />
                </div>
                <div className="login-form-container">
                    <p className="login-form-title">Bem-vindo de volta</p>
                    <p className="login-form-subtitle">Ainda não tem uma conta? <span className="create-account" onClick={dontHaveAnAccount}>Inscreva-se aqui</span></p>
                    <div className="login-form-inputs-container">
                        <TextInput
                            label="Seu CPF/CNPJ ou Email"
                            value={doc}
                            setValue={setDoc}
                            warningMessage={docWarning}
                        />
                        <HideTextInput
                            label="Sua senha"
                            value={password}
                            setValue={setPassword}
                            warningMessage={passwordWarning}
                        />
                         <div className="login-form-forgot-password-container">
                         <span className="forgot-text" onClick={handleForgotPasswordClick}>Esqueceu sua senha? Clique aqui</span>
                        </div>
                        <div className="login-form-forgot-password-container">
                        {/* <span className="forgot-text" onClick={handleForgotEmailClick}> Esqueceu seu email? Clique aqui</span> */}
                        </div>

                    </div>
                    <button type="submit" className="login-form-button" onClick={onClick}>
                        {
                            loading ? <LinearLoader white /> : <p>Entrar</p>
                        }
                    </button>
                    {errorMessage && (
                        <div className="login-form-error-message">
                            <p>{errorMessage}</p>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
}

export default LoginForm;
