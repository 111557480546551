import React, { useState, useEffect } from "react";

import TopBar from "../../layout/TopBar/TopBar";
import './styles.css';

import SearchBar from "../../components/TextInput/SearchBar";
import PromoCard from "../../components/PromoCard/PromoCard";
import BuyNow from "../../components/Home/BuyNow/BuyNow";

import useAuthClient from '../../hooks/useAuthClient';
import useSetPageTitle from "../../hooks/useSetPageTitle";

import promosBack from "./backend/promos";

function Promos() {

    useAuthClient();
    useSetPageTitle('Promos Juntos+ | Promos');

    const [promos, setPromos] = useState(promosBack);
    const [searchPromo, setSearchPromo] = useState('');

    useEffect(() => {
        const updatedPromos = promosBack.map(promo => {
            const promoAtiva = localStorage.getItem('PromoAtiva') === 'true';
            if (promo.title === 'Toby Carona Premiada') {
                return {
                    ...promo,
                    description: promoAtiva
                        ? 'Parabéns! Faça uma compra de no mínimo R$50 em produtos participantes e comece a acumular números da sorte agora mesmo!'
                        : 'A promoção começou! Clique no botão abaixo para participar!',
                    ended: !localStorage.getItem('ElegivelPromo'),
                    joined: promoAtiva,
                };
            }
            return promo;
        });
        setPromos(updatedPromos);
    }, []);

    const caronaPremiada = promos.find(promo => promo.title === 'Toby Carona Premiada');
    const otherPromos = promos.filter(promo => promo.title !== 'Toby Carona Premiada');

    return (
        <div className="background">
            <TopBar />
            <div className="promos-background">
                <SearchBar placeholder="Procure uma promoção" value={searchPromo} setValue={setSearchPromo} />
                
                {caronaPremiada && (
                    <div className="carona-premiada-container">
                        <PromoCard
                            key={caronaPremiada.title}
                            title={caronaPremiada.title}
                            description={caronaPremiada.description}
                            image={caronaPremiada.image}
                            ended={caronaPremiada.ended}
                            joined={caronaPremiada.joined}
                            newPromo={caronaPremiada.new}
                        />
                    </div>
                )}

                <div className="promos-container">
                    {
                        otherPromos
                        .filter((promo) => promo.title.toLowerCase().includes(searchPromo.toLowerCase()))
                        .map((promo) => (
                            <PromoCard
                                key={promo.title}
                                title={promo.title}
                                description={promo.description}
                                image={promo.image}
                                ended={promo.ended}
                                joined={promo.joined}
                                newPromo={promo.new}
                            />
                        ))
                    }
                </div>
                <BuyNow />
            </div>
        </div>
    )
}

export default Promos;
