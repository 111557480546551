import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import './styles.css';

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import semanalMobile from '../../../assets/banner/semanal-mobile.png';
import finalMobile from '../../../assets/banner/final-mobile.png';
import welcomeMobile from '../../../assets/banner/welcome-mobile.png';
import winnerMobile from '../../../assets/banner/winner-mobile.png';

import semanalDesktop from '../../../assets/banner/semanal-desktop.png';
import finalDesktop from '../../../assets/banner/final-desktop.png';
import welcomeDesktop from '../../../assets/banner/welcome-carona.png';
import winnerDesktop from '../../../assets/banner/winner-desktop.png';

function Banner({ haveVouchers, pendingPrizes }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [isPromotionActive, setIsPromotionActive] = useState(false);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);
    const isMobile = width < 768;

    useEffect(() => {
        const PromoAtiva = localStorage.getItem('PromoAtiva');
        if (PromoAtiva === 'true') {
            setIsPromotionActive(true);
        }
    }, []);

    const navigate = useNavigate();

    const slides = [
        !haveVouchers && {
            id: "welcome",
            image: isMobile ? welcomeMobile : welcomeDesktop,
            backgroundColor: isMobile ? 'transparent' : '#651C32',
            link: isPromotionActive ? "https://onelink.to/juntsplusbrv4" : "promos",
            text: isPromotionActive ? "Compre agora" : "Participe agora",
            target: isPromotionActive ? "_blank" : "_self",
            ctaClass: "home-banner-cta-container-welcome"
        },
        pendingPrizes && {
            id: "winner",
            image: isMobile ? winnerMobile : winnerDesktop,
            backgroundColor: isMobile ? 'transparent' : '#DA291C',
            link: () => navigate('/premios'),
            text: "Veja seus prêmios",
            ctaClass: "home-banner-cta-container-winner"
        },
        {
            id: "semanal",
            image: isMobile ? semanalMobile : semanalDesktop,
            backgroundColor: isMobile ? 'transparent' : '#DA291C',
            link: isPromotionActive ? "https://onelink.to/juntsplusbrv4" : "promos",
            text: isPromotionActive ? "Compre agora" : "Participe agora",
            target: isPromotionActive ? "_blank" : "_self",
        },
        {
            id: "final",
            image: isMobile ? finalMobile : finalDesktop,
            backgroundColor: isMobile ? 'transparent' : '#DA291C',
            link: isPromotionActive ? "https://onelink.to/juntsplusbrv4" : "promos",
            text: isPromotionActive ? "Compre agora" : "Participe agora",
            target: isPromotionActive ? "_blank" : "_self",
            ctaClass: "home-banner-cta-container-final"
        }
    ].filter(Boolean);

    return (
        <Swiper
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper home-banner-container"
        >
            {slides.map(slide => (
                <SwiperSlide key={slide.id} className="home-banner-page-container">
                    <div className="home-banner-page" style={{ backgroundColor: slide.backgroundColor }}>
                        <img src={slide.image} alt={`banner-${slide.id}`} className="home-banner-img" id={`${slide.id}-img`} />
                        <div className={`home-banner-cta-container ${slide.ctaClass || ''}`} onClick={typeof slide.link === 'function' ? slide.link : null}>
                            {typeof slide.link === 'string' ? (
                                <a
                                    className="home-banner-cta-text"
                                    href={slide.link}
                                    target={slide.target}
                                    rel="noreferrer"
                                >
                                    {slide.text}
                                </a>
                            ) : (
                                <p className="home-banner-cta-text">{slide.text}</p>
                            )}
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default Banner;
