import React, { useState, useEffect } from 'react';
import './styles.css';

function BuyNow() {
    const [isPromotionActive, setIsPromotionActive] = useState(false);

    useEffect(() => {
        const PromoAtiva = localStorage.getItem('PromoAtiva');
        if (PromoAtiva === 'true') {
            setIsPromotionActive(true);
        }
    }, []);

    return (
        <div className="buy-now-container">
            {isPromotionActive ? (
                <p className="buy-now-text">
                    Faça uma compra agora pelo Juntos+ e comece a acumular números da sorte para concorrer a prêmios toda semana!
                </p>
            ) : (
                <p className="buy-now-text">
                    Falta <b>MUITO POUCO</b> pra você participar da nossa nova promoção! Clique no botão abaixo e ative o seu cadastro na <b>TOBY CARONA PREMIADA!</b>
                </p>
            )}
            <div className="buy-now-cta-container">
                <a
                    className="buy-now-cta"
                    href={isPromotionActive ? "https://onelink.to/juntsplusbrv4" : "promos"}
                    target={isPromotionActive ? "_blank" : "_self"}
                    rel="noreferrer"
                >
                    {isPromotionActive ? "Compre agora" : "Participe agora"}
                </a>
            </div>
        </div>
    );
}

export default BuyNow;
